import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { TranslocoService } from '@ngneat/transloco';
import { Store } from '@ngrx/store';
import { isNil } from 'lodash';
import { BehaviorSubject, Observable, Subscription, of, timer } from 'rxjs';
import { debounce, filter, shareReplay, switchMap } from 'rxjs/operators';
import { NumberResult, Task, TestItemResult, TestItemResultStatusEnum, TestItemResultTypeEnum } from 'src/api/testrunner/models';
import { PipingItem } from '../../models/piping-item';
import { TestItemModel } from '../../models/test-item-model';
import { DateTimeService } from '../../services/date-time.service';
import { MacrosPipe } from '../../services/macros.pipe';
import { PersistenceService } from '../../services/persistence.service';
import { lockButtonNext } from '../../store/actions/page.actions';

const DEBOUNCE_TIME: number = 300;

@Component({
  selector: 'app-number',
  templateUrl: './number.component.html',
  styleUrls: ['./number.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NumberComponent implements OnChanges, OnInit, OnDestroy {
  @Input() public testItemModel?: TestItemModel;
  @Input() public index?: number;
  @Output() public readonly answerChanges: EventEmitter<TestItemResult> = new EventEmitter<TestItemResult>();

  public description?: SafeHtml;
  public formAnswer!: UntypedFormGroup;
  public number?: Task;
  private maskDelimiter?: string | undefined;

  private readonly shouldLengthHintBeVisible$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public readonly lengthHint$: Observable<string> = this.shouldLengthHintBeVisible$.pipe(
    filter((shouldLengthHintBeVisible: boolean) => shouldLengthHintBeVisible),
    switchMap(() => {
      const isMinValueEnabled: boolean = Boolean(this.number?.minValueIsEnabled && !isNil(this.number?.minValue));
      const isMaxValueEnabled: boolean = Boolean(this.number?.maxValueIsEnabled && !isNil(this.number?.maxValue));

      if (isMinValueEnabled && isMaxValueEnabled) {
        return this.translocoService.selectTranslate('testrunner.number.minMaxValue', {
          minValue: this.number?.minValue,
          maxValue: this.number?.maxValue,
        });
      }

      if (isMinValueEnabled) {
        return this.translocoService.selectTranslate('testrunner.number.minValue', { minValue: this.number?.minValue });
      }

      if (isMaxValueEnabled) {
        return this.translocoService.selectTranslate('testrunner.number.maxValue', { maxValue: this.number?.maxValue });
      }

      return of('');
    }),
    shareReplay({ bufferSize: 1, refCount: true }),
  );

  private readonly subscription: Subscription = new Subscription();

  private descriptionString: string = '';

  constructor(
    private readonly persistenceService: PersistenceService,
    private readonly fb: UntypedFormBuilder,
    private readonly domSanitizer: DomSanitizer,
    private readonly pipe: MacrosPipe,
    private readonly store: Store,
    private readonly translocoService: TranslocoService,
  ) {}

  public get locale(): string {
    return this.maskDelimiter === '.' ? 'en-US' : 'de-DE';
  }

  public get minFractionDigits(): number {
    const maskDelimiter = this.number?.mask?.includes('.') ? '.' : this.number?.mask?.includes(',') ? ',' : undefined;

    if (maskDelimiter) {
      const arrMask = this.number?.mask?.split(maskDelimiter, 2) ?? [];
      return arrMask.length > 1 ? arrMask[1].length : 0;
    }

    return 0;
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.testItemModel.previousValue?.lastUpdateTime !== changes.testItemModel.currentValue?.lastUpdateTime &&
      this.testItemModel?.data &&
      this.testItemModel?.data?.description &&
      this.descriptionString !== this.testItemModel.data.description // cannot be replaced with prev/curr value because of lastUpdateTime
    ) {
      const pipingItem = {
        ...this.testItemModel?.data,
        iterationId: this.testItemModel?.iterationId,
      } as PipingItem;
      this.descriptionString = this.testItemModel.data.description;
      this.description = this.domSanitizer.bypassSecurityTrustHtml(this.pipe.transform(this.testItemModel.data.description, pipingItem));
    }
  }

  public ngOnInit(): void {
    this.number = this.testItemModel?.data;
    const answer: NumberResult | null = this.persistenceService.get('answer_' + this.number?.id) || null;
    const isSkipped: boolean = Boolean(answer?.isAnswerSkipped && this.number?.isSkipButtonEnabled ? true : false);
    this.formAnswer = this.fb.group({
      answer: [
        {
          value: answer ? answer.answer : null,
          disabled: isSkipped,
        },
      ],
      isAnswerSkipped: isSkipped,
    });

    this.subscription.add(this.processFormAnswerValueChanges());

    // сохраним промежуточное значение по умолчанию
    if (this.number?.defaultValueIsEnabled && (!answer || answer.answer === null || answer.answer === undefined)) {
      this.formAnswer.patchValue({ answer: this.number?.defaultValue ?? 0 }, { emitEvent: true });
    }

    const result = this.number?.mask?.match(/\.|,/);
    this.maskDelimiter = result ? result[0] : undefined;

    const shouldHintBeVisible: boolean = Boolean(this.number?.minValueIsEnabled) || Boolean(this.number?.maxValueIsEnabled);
    this.shouldLengthHintBeVisible$.next(shouldHintBeVisible);
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private processFormAnswerValueChanges(): Subscription {
    return this.formAnswer.valueChanges
      .pipe(
        debounce(() => {
          this.store.dispatch(lockButtonNext());
          return timer(DEBOUNCE_TIME);
        }),
      )
      .subscribe((result) => {
        let answerResult = result.answer;
        if (result.isAnswerSkipped) {
          answerResult = null;
          this.formAnswer.controls.answer.setValue(answerResult, { emitEvent: false });
          this.formAnswer.controls.answer.disable({ emitEvent: false });
        } else {
          this.formAnswer.controls.answer.enable({ emitEvent: false });
        }

        this.save(answerResult, result.isAnswerSkipped);
      });
  }

  private save(result: number, isAnswerSkipped: boolean): void {
    this.answerChanges.emit({
      answer: result ?? null,
      answerTimeSpentMs: DateTimeService.getDuration(this.testItemModel?.showStartTime),
      clientStartTimeUtc: this.testItemModel?.showStartTime ?? DateTimeService.currentDateTimeUTC,
      iterationId: this.testItemModel?.iterationId,
      testId: this.testItemModel?.data.testId,
      testItemId: this.testItemModel?.data.id,
      type: TestItemResultTypeEnum.Number,
      status: TestItemResultStatusEnum.Intermediate,
      isAnswerSkipped,
    } as NumberResult);
  }
}
