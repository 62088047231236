/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { ApiConfiguration } from '../api-configuration';
import { BaseService } from '../base-service';
import { EmptyObject } from '../models/empty-object';
import { SetCacheRequest } from '../models/set-cache-request';
import { RequestBuilder } from '../request-builder';
import { StrictHttpResponse } from '../strict-http-response';

@Injectable({
  providedIn: 'root',
})
export class StorageService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation storageGetValue
   */
  static readonly StorageGetValuePath = '/api/v1/Storage';

  /**
   * Получение значения кеша по ключу.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `storageGetValue()` instead.
   *
   * This method doesn't expect any request body.
   */
  storageGetValue$Response(params?: { key?: string }): Observable<StrictHttpResponse<string>> {
    const rb = new RequestBuilder(this.rootUrl, StorageService.StorageGetValuePath, 'get');
    if (params) {
      rb.query('key', params.key, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<string>;
        }),
      );
  }

  /**
   * Получение значения кеша по ключу.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `storageGetValue$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  storageGetValue(params?: { key?: string }): Observable<string> {
    return this.storageGetValue$Response(params).pipe(map((r: StrictHttpResponse<string>) => r.body as string));
  }

  /**
   * Path part for operation storageSetValue
   */
  static readonly StorageSetValuePath = '/api/v1/Storage';

  /**
   * Сохранение кеша.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `storageSetValue()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  storageSetValue$Response(params?: { body?: SetCacheRequest }): Observable<StrictHttpResponse<EmptyObject>> {
    const rb = new RequestBuilder(this.rootUrl, StorageService.StorageSetValuePath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<EmptyObject>;
        }),
      );
  }

  /**
   * Сохранение кеша.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `storageSetValue$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  storageSetValue(params?: { body?: SetCacheRequest }): Observable<EmptyObject> {
    return this.storageSetValue$Response(params).pipe(map((r: StrictHttpResponse<EmptyObject>) => r.body as EmptyObject));
  }
}
