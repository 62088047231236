import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormControl, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { TranslocoService, isNil } from '@ngneat/transloco';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, Subscription, of, timer } from 'rxjs';
import { debounce, filter, map, shareReplay, startWith, switchMap } from 'rxjs/operators';
import {
  AnswerFormatEnum,
  FreeFormResult,
  Task,
  TestItemResult,
  TestItemResultStatusEnum,
  TestItemResultTypeEnum,
} from 'src/api/testrunner/models';
import { PipingItem } from '../../models/piping-item';
import { TestItemModel } from '../../models/test-item-model';
import { DateTimeService } from '../../services/date-time.service';
import { MacrosPipe } from '../../services/macros.pipe';
import { PersistenceService } from '../../services/persistence.service';
import { lockButtonNext } from '../../store/actions/page.actions';

const DEBOUNCE_TIME: number = 300;

const EMAIL_PATTERN: RegExp = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w+)+$/i;

@Component({
  selector: 'app-free-form',
  templateUrl: './free-form.component.html',
  styleUrls: ['./free-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FreeFormComponent implements OnChanges, OnInit, OnDestroy {
  @Input() public index?: number;
  @Input() public testItemModel?: TestItemModel;

  @Output() public readonly answerChanges: EventEmitter<TestItemResult> = new EventEmitter<TestItemResult>();

  public description?: SafeHtml;
  public formAnswer!: UntypedFormGroup;
  public readonly formats: typeof AnswerFormatEnum = AnswerFormatEnum;
  public freeForm?: Task;
  public isValidEmail: boolean = true;
  public isValidLength: boolean = true;
  private descriptionString: string = '';

  private readonly subscription: Subscription = new Subscription();

  private readonly shouldLengthHintBeVisible$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public readonly lengthHint$: Observable<string> = this.shouldLengthHintBeVisible$.pipe(
    filter((shouldLengthHintBeVisible: boolean) => shouldLengthHintBeVisible),
    switchMap(() => this.formAnswer.controls.answer.valueChanges.pipe(startWith(this.formAnswer.controls.answer.value))),
    map(() => this.formAnswer.controls.answer as FormControl<string>),
    switchMap((answerControl: FormControl<string>) => {
      const isMinLengthEnabled: boolean = Boolean(this.freeForm?.minValueIsEnabled && !isNil(this.freeForm?.answerMinLength));

      if (answerControl.pristine && isMinLengthEnabled) {
        return this.translocoService.selectTranslate('testrunner.freeform.minLength', { minLength: this.freeForm?.answerMinLength });
      }

      const answerLength: number = this.clearAnswerText(answerControl.value).length;

      const isMinLengthHintVisible: boolean = isMinLengthEnabled && answerLength < (this.freeForm?.answerMinLength ?? 0);
      if (isMinLengthHintVisible) {
        return this.translocoService.selectTranslate('testrunner.freeform.limitNotReached', {
          remainingCount: (this.freeForm?.answerMinLength ?? 0) - answerLength,
        });
      }

      const isMaxLengthHintVisible: boolean = Boolean(this.freeForm?.maxValueIsEnabled && !isNil(this.freeForm.answerMaxLength));

      if (isMaxLengthHintVisible) {
        return this.translocoService.selectTranslate('testrunner.freeform.currentLimitCount', {
          current: answerLength,
          total: this.freeForm?.answerMaxLength,
        });
      }

      return of('');
    }),
    shareReplay({ bufferSize: 1, refCount: true }),
  );

  constructor(
    private readonly persistenceService: PersistenceService,
    private readonly fb: UntypedFormBuilder,
    private readonly domSanitizer: DomSanitizer,
    private readonly pipe: MacrosPipe,
    private readonly store: Store,
    private readonly translocoService: TranslocoService,
  ) {}

  public get maxLength(): number | string {
    return this.freeForm?.maxValueIsEnabled && this.freeForm?.answerMaxLength ? this.freeForm?.answerMaxLength : '';
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.testItemModel.previousValue?.lastUpdateTime !== changes.testItemModel.currentValue?.lastUpdateTime &&
      this.testItemModel?.data &&
      this.testItemModel?.data?.description &&
      this.descriptionString !== this.testItemModel.data.description // cannot be replaced with prev/curr value because of lastUpdateTime
    ) {
      const pipingItem = {
        ...this.testItemModel.data,
        iterationId: this.testItemModel.iterationId,
      } as PipingItem;

      this.descriptionString = this.testItemModel.data.description;
      this.description = this.domSanitizer.bypassSecurityTrustHtml(this.pipe.transform(this.testItemModel.data.description, pipingItem));
    }
  }

  public ngOnInit(): void {
    this.freeForm = this.testItemModel?.data;

    // принудительно обнулим флаг answerLengthLimitsEnabled, т.к. он больше не используется
    this.freeForm = {
      ...this.freeForm,
      answerLengthLimitsEnabled: false,
    } as Task;

    const answer: FreeFormResult | null = this.persistenceService.get('answer_' + this.freeForm?.id ?? '') || null;
    this.isValidLength = this.checkValidLength(answer?.elementStrings ? answer.elementStrings[0] : '');
    const isSkipped: boolean = Boolean(answer?.isAnswerSkipped && this.freeForm?.isSkipButtonEnabled);
    this.formAnswer = this.fb.group({
      answer: [
        {
          value: answer ? answer.elementStrings : '',
          disabled: isSkipped,
        },
      ],
      isAnswerSkipped: isSkipped,
    });

    const shouldHintBeVisible: boolean =
      this.freeForm.answerFormat === AnswerFormatEnum.OneRow ||
      (this.freeForm.answerFormat === AnswerFormatEnum.MultiRow &&
        (Boolean(this.freeForm.minValueIsEnabled) || Boolean(this.freeForm.maxValueIsEnabled)));

    this.shouldLengthHintBeVisible$.next(shouldHintBeVisible);

    this.subscription.add(this.processFormAnswerValueChanges());
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private checkValidEmail(answer: string): boolean {
    return this.clearAnswerText(answer).length === 0 || EMAIL_PATTERN.test(answer);
  }

  private checkValidLength(answer: string): boolean {
    const answerLength = this.clearAnswerText(answer).length;

    if (answerLength > 0) {
      if (this.freeForm?.minValueIsEnabled && this.freeForm.answerMinLength && answerLength < this.freeForm.answerMinLength) {
        return false;
      }

      if (this.freeForm?.maxValueIsEnabled && this.freeForm.answerMaxLength && answerLength > this.freeForm.answerMaxLength) {
        return false;
      }
    }

    return true;
  }

  private clearAnswerText(answer: string): string {
    return answer?.replace(/(\r\n|\n|\r)/gm, '') ?? '';
  }

  /*clearPhoneNumber(answer: string): string {
    return answer.replace(/[^0-9]/gm, '');
  }*/

  private save(result: string, isAnswerSkipped: boolean): void {
    this.answerChanges.emit({
      answerTimeSpentMs: DateTimeService.getDuration(this.testItemModel?.showStartTime),
      clientStartTimeUtc: this.testItemModel?.showStartTime ?? DateTimeService.currentDateTimeUTC,
      elementStrings: [result ?? ''],
      iterationId: this.testItemModel?.iterationId,
      testId: this.testItemModel?.data.testId,
      testItemId: this.testItemModel?.data.id,
      type: TestItemResultTypeEnum.FreeForm,
      status: TestItemResultStatusEnum.Intermediate,
      isAnswerSkipped,
    } as FreeFormResult);
  }

  private processFormAnswerValueChanges(): Subscription {
    return this.formAnswer.valueChanges
      .pipe(
        debounce(() => {
          this.store.dispatch(lockButtonNext());
          return timer(DEBOUNCE_TIME);
        }),
      )
      .subscribe((result) => {
        let answerResult: string = result.answer;

        /*if (this.freeForm?.answerFormat === AnswerFormatEnum.PhoneNumber) {
        result.answer = this.clearPhoneNumber(result.answer);
      }*/

        if (result.isAnswerSkipped) {
          answerResult = '';
          this.formAnswer.controls.answer.setValue('', { emitEvent: false });
          this.formAnswer.controls.answer.disable({ emitEvent: false });
        } else {
          this.formAnswer.controls.answer.enable({ emitEvent: false });
        }

        this.isValidLength = this.checkValidLength(result.answer);
        console.log('isValidLength', this.isValidLength);
        this.isValidEmail = this.checkValidEmail(result.answer);

        this.save(answerResult, result.isAnswerSkipped);
      });
  }
}
