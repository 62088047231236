import { DOCUMENT } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { TranslocoService } from '@ngneat/transloco';
import { Store } from '@ngrx/store';
import { IfCompleteResultStatusEnum, Test, TestTypeEnum } from 'src/api/testrunner/models';
import { CompleteTestInfo } from '../../models/complete-test-info';
import { MobileAppActionEnum } from '../../models/mobile-app-action-enum';
import { WebSiteActionEnum } from '../../models/web-site-action-enum';
import { MacrosPipe } from '../../services/macros.pipe';
import { MobileService } from '../../services/mobile.service';
import { WebSiteService } from '../../services/web-site.service';
import { finishSessionAction } from '../../store/actions/test-runner.actions';

@Component({
  selector: 'app-test-end',
  templateUrl: './test-end.component.html',
  styleUrls: ['./test-end.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TestEndComponent implements OnInit {
  @Input() public completeTestInfo: CompleteTestInfo | null = null;
  @Input() public isFinishSession = true;
  @Input() public redirectUrl: string | null = null;
  @Input() public test: Test | null = null;

  public completeContent: SafeHtml = '';

  constructor(
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly macrosPipe: MacrosPipe,
    private readonly mobileService: MobileService,
    private readonly sanitizer: DomSanitizer,
    private readonly store: Store,
    private readonly transloco: TranslocoService,
    private readonly webSiteService: WebSiteService,
  ) {}

  ngOnInit(): void {
    if (this.isFinishSession) {
      this.store.dispatch(finishSessionAction());
    }

    this.completeContent = this.sanitizer.bypassSecurityTrustHtml(
      this.completeTestInfo?.completeContent
        ? this.macrosPipe.transform(this.completeTestInfo.completeContent)
        : this.getDefaultText(this.completeTestInfo?.completeStatusType),
    );
    const redirectUrl: string = this.redirectUrl ? this.macrosPipe.transform(this.redirectUrl.trim()) : '';

    if(window.top != window.self) {
        window.parent.postMessage({ action: 'FinishTest' }, '*');
    }

    if (this.test?.type === TestTypeEnum.Mobile) {
      this.mobileService.finishUrl = redirectUrl;
      this.mobileService.postMessage(MobileAppActionEnum.FinishTask);
      this.mobileService.postMessage(MobileAppActionEnum.FinishTest);
    }

    if (this.test?.type === TestTypeEnum.WebSite) {
      this.webSiteService.postMessage(WebSiteActionEnum.FinishTest);
    }

    if (redirectUrl) {
      const redirectAfterDelay = setTimeout(() => {
        this.document.location.href = redirectUrl;
        clearTimeout(redirectAfterDelay);
      }, 1000);
    }

    /*if (this.webSiteService.videos.length) {
      this.webSiteService.videos.forEach((video, index) => {
        if (index === this.webSiteService.videos.length - 1) {
          video.final = true;
        }
        this.subscriptions.add(this.videoService.videoSaveVideo(video).subscribe());
      });
      this.webSiteService.clearVideos();
    }*/
  }

  private getDefaultText(completeStatusType?: IfCompleteResultStatusEnum): string {
    switch (completeStatusType) {
      case IfCompleteResultStatusEnum.Cheater:
        return this.transloco.translate('testrunner.redirectLink.cheaterContent.defaultText');
      case IfCompleteResultStatusEnum.FiltersNotPassed:
        return this.transloco.translate('testrunner.redirectLink.filtersNotPassedContent.defaultText');
      case IfCompleteResultStatusEnum.LateUser:
        return this.transloco.translate('testrunner.redirectLink.lateUserContent.defaultText');
      default:
        return this.transloco.translate('testrunner.redirectLink.finishContent.defaultText');
    }
  }
}
