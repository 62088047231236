import { HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap } from 'rxjs/operators';

import { backendErrorsAction, emptyAction, getPromoCodeErrorAction, getTokenAction } from '../actions/test-runner.actions';

import { isNil } from 'lodash';
import { of } from 'rxjs';
import { UrlParams } from 'src/app/models/url-params';
import { PersistenceService } from 'src/app/services/persistence.service';
import { saveAnswersErrorAction } from '../actions/answers.actions';

@Injectable()
export class BackendErrorsEffects {
  redirectToWorkspace$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(backendErrorsAction, getPromoCodeErrorAction, saveAnswersErrorAction),
      switchMap(({ error }) => {
        switch (error.status) {
          case HttpStatusCode.Unauthorized:
          case 0: {
            const params: UrlParams | null = this.getParams();

            this.persistanceService.remove('testRunnerToken');

            return isNil(params) ? of(emptyAction()) : of(getTokenAction({ urlParams: params }));
          }
        }
        return of(emptyAction());
      }),
    );
  });

  constructor(
    private actions$: Actions,
    private readonly activatedRoute: ActivatedRoute,
    private readonly persistanceService: PersistenceService,
  ) {}

  private getParams(): UrlParams | null {
    const queryParams: Params | undefined = this.activatedRoute.snapshot.children[0]?.queryParams;
    const params: Params | undefined = this.activatedRoute.snapshot.children[0]?.params;

    if (isNil(queryParams) || isNil(params)) {
      return null;
    }

    return {
      anonymousId: queryParams?.anonymousId ?? '',
      fabUniqueId: queryParams?.fabUniqueId ?? '',
      logicOn: !(queryParams?.logicOn === 'false'),
      preview: queryParams?.preview === 'true',
      queryString: new URL(window.document.location.href).searchParams.toString(),
      sapOutboundId: queryParams?.['sap-outbound-id'] ?? '',
      taskPreviewId: queryParams?.taskId,
      testId: params?.id ?? '',
      token: queryParams?.token ?? '',
    } as UrlParams;
  }
}
