import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { isNil } from 'lodash';
import moment from 'moment';
import { combineLatest, of } from 'rxjs';
import { catchError, concatMap, exhaustMap, filter, map, retry, switchMap, tap } from 'rxjs/operators';
import { StorageService } from 'src/api/kv-storage/services/storage.service';
import {
  FinishSessionRequest,
  IfCompleteResultStatusEnum,
  StartSessionStatusEnum,
  TestItem,
  TestItemResult,
  TestItemResultTypeEnum,
  TestItemTypeEnum,
  TestRunnerStartData,
  TestSessionEndStatusEnum,
  TestTypeEnum,
} from 'src/api/testrunner/models';
import { StartSessionRequest } from 'src/api/testrunner/models/start-session-request';
import { SessionService, TokenService } from 'src/api/testrunner/services';
import { CompleteTestInfo } from '../../models/complete-test-info';
import { MobileAppActionEnum } from '../../models/mobile-app-action-enum';
import { TestInfo } from '../../models/test-info';
import { TestStatusEnum } from '../../models/test-status.enum';
import { WebSiteActionEnum } from '../../models/web-site-action-enum';
import { DateTimeService } from '../../services/date-time.service';
import { MacrosService } from '../../services/macros.service';
import { MobileService } from '../../services/mobile.service';
import { PersistenceService } from '../../services/persistence.service';
import { RedirectService } from '../../services/redirect.service';
import { WebSiteService } from '../../services/web-site.service';
import { getCurrentPageAction, getNextPageAction, getNextPageSuccessAction, getPrevPageSuccessAction } from '../actions/page.actions';
import {
  backendErrorsAction,
  finishMobileTestAction,
  finishSessionAction,
  finishSessionSuccessAction,
  finishTestAction,
  getSessionCacheAction,
  getTokenAction,
  screenOutAction,
  setEyeTrackerInfo,
  setSessionCacheAction,
  setSessionCacheSuccessAction,
  setTestInfoAction,
  startMobileSessionAction,
  startSessionAction,
  startSessionErrorAction,
  startSessionSuccessAction,
} from '../actions/test-runner.actions';
import { selectPreviousPage } from '../selectors/page.selectors';
import { selectParentPageItem } from '../selectors/test-items.selectors';
import { selectCompleteTestInfo, selectTestInfo, selectTestRunnerStartData, selectTestStartData } from '../selectors/test-runner.selectors';

const TEST_STARTED_STATUSES: Set<StartSessionStatusEnum> = new Set<StartSessionStatusEnum>([
  StartSessionStatusEnum.Success,
  StartSessionStatusEnum.AlreadyStarted,
  StartSessionStatusEnum.PreviewMode,
  StartSessionStatusEnum.Finished,
]);

const ERROR_RETRY_DELAY: number = 3_000;

@Injectable()
export class SessionEffects {
  /**
   * Обработка завершения мобильной сессии
   */
  finishMobileSession$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(finishSessionSuccessAction),
      concatLatestFrom(() => [this.store.select(selectTestInfo), this.store.select(selectTestRunnerStartData)]),
      filter(([, testInfo, testRunnerStartData]) => testRunnerStartData?.test?.type === TestTypeEnum.Mobile && !testInfo?.preview),
      exhaustMap(([, testInfo]) =>
        this.storageService
          .storageSetValue({
            body: {
              key: 'isMobileSessionFinished_' + testInfo?.testId + '&' + this.persistenceService.get('anonymousId'),
              value: 'true',
            },
          })
          .pipe(
            map(() => setSessionCacheAction({ testId: testInfo?.testId ?? '' })),

            catchError((errorResponse: unknown) => of(backendErrorsAction({ error: errorResponse as HttpErrorResponse }))),
          ),
      ),
    );
  });

  /**
   * Отправка данных о завершении сессии
   */
  finishSession$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(finishSessionAction),
      concatLatestFrom(() => [this.store.select(selectTestInfo), this.store.select(selectCompleteTestInfo)]),
      exhaustMap(([, testInfo, completeTestInfo]) => {
        const finishSessionRequest = {
          clientEndTimeUtc: DateTimeService.currentDateTimeUTC,
          clientTimeZoneMinutes: DateTimeService.timezone,
          status: completeTestInfo?.completeStatusType
            ? (completeTestInfo.completeStatusType as string as TestSessionEndStatusEnum)
            : TestSessionEndStatusEnum.Finished,
        } as FinishSessionRequest;
        return this.sessionService
          .testsFinishSession({
            testId: testInfo?.testId ?? '',
            preview: testInfo?.preview ?? false,
            body: finishSessionRequest,
          })
          .pipe(
            map(() => finishSessionSuccessAction()),

            catchError((errorResponse: unknown) => of(backendErrorsAction({ error: errorResponse as HttpErrorResponse }))),
          );
      }),
    );
  });

  /**
   * Отправка пост мессэджей о завершении тасков
   */
  finishTask = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(finishSessionAction),
        concatLatestFrom(() => this.store.select(selectPreviousPage)),
        tap(([, prevPage]) => {
          if (prevPage && TestItemTypeEnum.Mobile === prevPage?.testItemType) {
            this.mobileService.postMessage(MobileAppActionEnum.FinishTask, prevPage);
          }
        }),
      );
    },
    { dispatch: false },
  );

  /**
   * Обработка принудительного завершения теста по постмессаджу
   */
  finishTest$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(finishMobileTestAction),
      concatLatestFrom(() => [this.store.select(selectTestStartData), this.store.select(selectTestInfo)]),
      map(([, test, testInfo]) => {
        const url = test?.filtersNotPassedUrl ?? null;
        const completeTestInfo = this.redirectService.getForceFinishTestData(test);
        this.persistenceService.set('finishSession_' + test?.id, completeTestInfo);
        this.persistenceService.set('redirectUrl_' + test?.id, url);
        return finishTestAction({
          completeTestInfo,
          testInfo: {
            ...testInfo,
            currentPageId: null,
            status: TestStatusEnum.Completed,
            stack: [],
          } as TestInfo,
          url,
        });
      }),
    );
  });

  /**
   * Синхронизация сохраненного локалстораджа с бэка
   */
  getSessionCache$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getSessionCacheAction), ///
      concatMap(({ urlParams }) =>
        this.storageService.storageGetValue({ key: 'session_' + urlParams.testId + '&' + urlParams?.anonymousId }).pipe(
          map((sessionCacheData) => {
            if (sessionCacheData) {
              this.persistenceService.setCachedLocalStorage(sessionCacheData);
            }
            return startSessionAction({ urlParams });
          }),

          catchError((errorResponse: unknown) => of(backendErrorsAction({ error: errorResponse as HttpErrorResponse }))),
        ),
      ),
    );
  });

  /**
   * Поиск стартовой страницы
   */
  getStartPage$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(setTestInfoAction),
      filter(({ testInfo }) => testInfo !== null),
      map(({ testInfo }) => {
        switch (testInfo?.status) {
          case TestStatusEnum.OnStart:
            return getNextPageAction({});
          case TestStatusEnum.InProgress:
            return getCurrentPageAction();
          default: {
            const completeTestInfo = this.persistenceService.get('finishSession_' + testInfo?.testId) ?? null;
            const url = this.persistenceService.get('redirectUrl_' + testInfo?.testId) ?? null;
            return finishTestAction({
              testInfo,
              completeTestInfo,
              url,
            });
          }
        }
      }),
    );
  });

  /**
   * Получение токена и обновление в локалсторадже
   */
  getToken$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getTokenAction),
      exhaustMap((v) => combineLatest([of(v), this.persistenceService.getAsync('testRunnerToken')])),
      switchMap(([{ urlParams }, oldToken]) => {
        if (urlParams.token && oldToken !== urlParams.token) {
          this.persistenceService.set('testRunnerToken', urlParams.token);
          return of(getSessionCacheAction({ urlParams }));
        }

        if (oldToken) {
          return of(startSessionAction({ urlParams }));
        }

        return this.tokenService
          .tokensCreateTestRunnerToken({
            body: {
              sapOutboundId: urlParams.sapOutboundId ?? '',
              testId: urlParams.testId ?? '',
            },
          })
          .pipe(
            switchMap((tokenResponse) =>
              combineLatest([
                this.persistenceService.setAsync('testRunnerToken', !urlParams.token ? tokenResponse.token : urlParams.token),
                this.persistenceService.setAsync('anonymousId', !urlParams.anonymousId ? tokenResponse.anonymousId : urlParams.anonymousId),
              ]),
            ),
            map(() => {
              return startSessionAction({ urlParams });
            }),

            catchError((errorResponse: unknown) => of(backendErrorsAction({ error: errorResponse as HttpErrorResponse }))),
          );
      }),
    );
  });

  /**
   * Инициализация данных о тесте
   */
  initTest$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(startSessionSuccessAction),
      concatLatestFrom(({ urlParams }) => this.store.select(selectParentPageItem(urlParams?.taskPreviewId))),
      map(([{ testRunnerStartData, urlParams }, previewPage]) => {
        //Проверка установленно ли расширение
        if (testRunnerStartData.test?.type === TestTypeEnum.WebSite) {
          this.webSiteService.postMessage(WebSiteActionEnum.CheckInstalled);
        }
        //Проверка учтановлено ли приложение
        if (testRunnerStartData.test?.type === TestTypeEnum.Mobile) {
          this.mobileService.postMessage(MobileAppActionEnum.CheckInstalled);
        }
        //инициализация глобальных параметров сервисов
        this.macrosService.initialize(testRunnerStartData);

        return setTestInfoAction({
          testInfo:
            this.persistenceService.get('test_' + testRunnerStartData?.test?.id) ??
            ({
              testId: testRunnerStartData?.test?.id,
              currentPageId: previewPage?.id ?? null,
              currentIteratorInfo: null,
              eyeTrackerInfo: null,
              logicOn: urlParams.logicOn,
              preview: urlParams.preview,
              status: previewPage?.id ? TestStatusEnum.InProgress : TestStatusEnum.OnStart,
              stack: [],
              testStartTimestampUTC: DateTimeService.currentTimestampUTC,
              testTimeZone: DateTimeService.timezone,
            } as TestInfo),
        });
      }),
    );
  });

  /**
   * Редирект на урл указанный при скринауте
   */
  // редирект перенесен на страницу завершения
  /*redirectToUrl$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(finishSessionAction),
        concatLatestFrom(() => this.store.select(selectRedirectUrl)),
        filter(([, url]) => !!url),
        tap(([, url]) => setTimeout(() => (this.document.location.href = this.macrosPipe.transform((url ?? '').trim())), 1000))
      );
    },
    { dispatch: false }
  );*/

  /**
   * Обработка завершения теста по скрин ауту
   */
  screenOut$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(screenOutAction),
      concatLatestFrom(() => this.store.select(selectTestStartData)),
      map(([{ screenOutItem, testInfo }, test]) => {
        const { completeContent, url } = this.redirectService.getRedirectData(test, screenOutItem);
        const completeTestInfo = {
          completeStatusType: screenOutItem.completeStatusType ?? IfCompleteResultStatusEnum.Finished,
          completeContent,
          testId: test?.id ?? '',
        } as CompleteTestInfo;
        this.persistenceService.set('finishSession_' + test?.id, completeTestInfo);
        this.persistenceService.set('redirectUrl_' + test?.id, url);
        return finishTestAction({ completeTestInfo, testInfo, url });
      }),
    );
  });

  /**
   * Сохранение данные EyeTracker'а в локалсторадже
   */
  setEyeTrackerInfo$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(setEyeTrackerInfo),
      concatLatestFrom(() => this.store.select(selectTestInfo)),
      map(([{ eyeTrackerInfo }, testInfo]) =>
        setTestInfoAction({
          testInfo: testInfo
            ? {
                ...testInfo,
                eyeTrackerInfo: eyeTrackerInfo ?? null,
              }
            : null,
        }),
      ),
    );
  });

  /**
   * Сохранение локалстораджа на бэк
   */
  setSessionCache$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(setSessionCacheAction),
      exhaustMap(({ testId }) =>
        this.storageService
          .storageSetValue({
            body: {
              key: 'session_' + testId + '&' + this.persistenceService.get('anonymousId'),
              value: this.persistenceService.getLocalStorage(),
            },
          })
          .pipe(
            map(() => setSessionCacheSuccessAction()),

            catchError((errorResponse: unknown) => of(backendErrorsAction({ error: errorResponse as HttpErrorResponse }))),
          ),
      ),
    );
  });

  /**
   * Обновление данных о тесте в локалсторадже
   */
  setTestInfo$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(setTestInfoAction, finishTestAction, getNextPageSuccessAction, getPrevPageSuccessAction),
        filter(({ testInfo }) => testInfo !== null && testInfo?.testId !== null),
        tap(({ testInfo }) => {
          this.persistenceService.set('test_' + (testInfo?.testId ?? ''), testInfo);
        }),
      );
    },
    { dispatch: false },
  );

  /**
   * Проверка завершения мобильной сессии
   */
  startMobileSession$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(startMobileSessionAction),
      concatMap(({ testRunnerStartData, urlParams }) =>
        this.storageService
          .storageGetValue({
            key: 'isMobileSessionFinished_' + testRunnerStartData.test?.id + '&' + this.persistenceService.get('anonymousId'),
          })
          .pipe(
            map((value) => {
              let isMobileSessionFinished = false;
              try {
                isMobileSessionFinished = JSON.parse(value) as boolean;
              } catch {}
              return { testRunnerStartData, urlParams, isMobileSessionFinished };
            }),
          ),
      ),
      concatMap(({ testRunnerStartData, urlParams, isMobileSessionFinished }) => {
        if (isMobileSessionFinished) {
          return this.storageService
            .storageGetValue({ key: 'session_' + testRunnerStartData.test?.id + '&' + this.persistenceService.get('anonymousId') })
            .pipe(
              map((sessionCacheData) => {
                if (sessionCacheData) {
                  console.log(JSON.parse(sessionCacheData));
                  this.persistenceService.setCachedLocalStorage(sessionCacheData);
                }
                return startSessionSuccessAction({
                  answersQueue: this.persistenceService.get('answersQueue') ?? [],
                  isMobileAppInstalled: this.persistenceService.get('isMobileAppInstalled') ?? false,
                  testRunnerStartData,
                  urlParams,
                });
              }),
            );
        }

        return of(
          startSessionSuccessAction({
            answersQueue: this.persistenceService.get('answersQueue') ?? [],
            isMobileAppInstalled: this.persistenceService.get('isMobileAppInstalled') ?? false,
            testRunnerStartData,
            urlParams,
          }),
        );
      }),

      catchError((errorResponse: unknown) => of(backendErrorsAction({ error: errorResponse as HttpErrorResponse }))),
    );
  });

  /**
   * Получение данных о тесте с бэка и отправка данных о начале сессии
   */
  startSession$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(startSessionAction),
      exhaustMap(({ urlParams }) =>
        this.sessionService
          .testsStartSession({
            ...urlParams,
            body: {
              clientStartTimeUtc: DateTimeService.currentDateTimeUTC,
              clientTimeZoneMinutes: DateTimeService.timezone,
              queryString: urlParams.queryString,
              screenPixelsHeight: window.screen.height,
              screenPixelsWidth: window.screen.width,
            } as StartSessionRequest,
          })
          .pipe(
            retry({ count: 2, delay: ERROR_RETRY_DELAY, resetOnSuccess: true }),
            switchMap((testRunnerStartData: TestRunnerStartData) => {
              if (
                (testRunnerStartData.startSessionStatus !== StartSessionStatusEnum.AlreadyStarted &&
                  testRunnerStartData.startSessionStatus !== StartSessionStatusEnum.Finished) ||
                localStorage.length > 3 ||
                isNil(this.activatedRoute.snapshot.queryParams['sap-outbound-id'])
              ) {
                return of(testRunnerStartData);
              }
              return this.sessionService.getResults(testRunnerStartData.test?.id ?? '').pipe(
                tap((results: TestItemResult[]) => {
                  if (results.length === 0) {
                    return;
                  }

                  const groupItems: TestItem[] = testRunnerStartData?.test?.rootGroup?.groupItems ?? [];
                  const allPages: TestItem[] = groupItems.filter((testItem: TestItem) => testItem.testItemType === TestItemTypeEnum.Page);

                  const testEndResults: TestItemResult[] = [];
                  const pageResults: TestItemResult[] = [];
                  let testStart: string = '';

                  results.forEach((result: TestItemResult) => {
                    this.persistenceService.set('answer_' + result.testItemId, result);

                    if (result.type === TestItemResultTypeEnum.Page) {
                      pageResults.push(result);

                      if (testStart === '' || moment(result.clientStartTimeUtc).isBefore(moment(testStart))) {
                        testStart = result.clientStartTimeUtc ?? '';
                      }
                    }

                    if (result.type === TestItemResultTypeEnum.TestEndByCondition) {
                      testEndResults.push(result);
                    }
                  });

                  const current: TestInfo = {
                    currentIteratorInfo: null,
                    currentPageId: null,
                    eyeTrackerInfo: null,
                    logicOn: true,
                    preview: false,
                    stack: [],
                    status: TestStatusEnum.Completed,
                    testId: testRunnerStartData.test?.id ?? '',
                    testStartTimestampUTC: moment(testStart).utc().valueOf(),
                    testTimeZone: pageResults[0]?.clientTimeZoneMinutes ?? 180,
                  };

                  if (testRunnerStartData.startSessionStatus === StartSessionStatusEnum.Finished) {
                    this.persistenceService.set('test_' + (testRunnerStartData.test?.id ?? ''), current);
                    return;
                  }

                  const stack: string[] = allPages.slice(0, pageResults.length).map((testItem: TestItem) => testItem.id ?? '');
                  const newCurrentPageId: string | null = allPages[pageResults.length]?.id ?? null;

                  current.stack = stack;
                  current.currentPageId = newCurrentPageId;
                  current.status = TestStatusEnum.InProgress;

                  this.persistenceService.set('test_' + (testRunnerStartData.test?.id ?? ''), current);
                }),
                map(() => testRunnerStartData),
              );
            }),
            map((testRunnerStartData) => {
              if (testRunnerStartData.startSessionStatus && TEST_STARTED_STATUSES.has(testRunnerStartData.startSessionStatus)) {
                if (testRunnerStartData.test?.type === TestTypeEnum.Mobile) {
                  return startMobileSessionAction({ testRunnerStartData, urlParams });
                }
                return startSessionSuccessAction({
                  answersQueue: this.persistenceService.get('answersQueue') ?? [],
                  isMobileAppInstalled: false,
                  testRunnerStartData,
                  urlParams,
                });
              } else {
                return startSessionErrorAction({
                  completeTestInfo: {
                    completeContent: testRunnerStartData.test?.lateUserContent ?? '',
                    completeStatusType: IfCompleteResultStatusEnum.LateUser,
                    testId: testRunnerStartData.test?.id ?? '',
                  },
                  testRunnerStartData,
                  url: testRunnerStartData.test?.lateUserUrl ?? null,
                });
              }
            }),
            catchError((errorResponse: unknown) => of(backendErrorsAction({ error: errorResponse as HttpErrorResponse }))),
          ),
      ),
    );
  });

  constructor(
    private readonly actions$: Actions,
    private readonly macrosService: MacrosService,
    private readonly mobileService: MobileService,
    private readonly persistenceService: PersistenceService,
    private readonly redirectService: RedirectService,
    private readonly sessionService: SessionService,
    private readonly storageService: StorageService,
    private readonly store: Store,
    private readonly tokenService: TokenService,
    private readonly webSiteService: WebSiteService,
    private readonly activatedRoute: ActivatedRoute,
  ) {}
}
