<div *ngIf="description" [innerHtml]="description" class="pb-4"></div>

<form [formGroup]="formAnswer" *transloco="let translate">
  <div class="">
    <div>
      <p-inputNumber
        mode="decimal"
        [allowEmpty]="true"
        [showButtons]="true"
        [minFractionDigits]="minFractionDigits"
        [step]="number?.changeStepIsEnabled && number?.changeStep ? number?.changeStep : 1"
        [min]="number?.minValueIsEnabled ? number?.minValue : null"
        [max]="number?.maxValueIsEnabled ? number?.maxValue : null"
        [suffix]="number?.unitOfMeasurement ? ' ' + number?.unitOfMeasurement : ''"
        [locale]="locale"
        formControlName="answer"
        class="w-full"
        styleClass="w-full"
        incrementButtonClass="p-togglebutton p-button border-left-none border-bottom-none"
        decrementButtonClass="p-togglebutton p-button border-left-none"
      ></p-inputNumber>
      <p class="length-hint">{{ lengthHint$ | async }}</p>
    </div>
  </div>
  <p-checkbox
    *ngIf="number?.isSkipButtonEnabled"
    inputId="isSkipButtonEnabled"
    name="isSkipButtonEnabled"
    [binary]="true"
    formControlName="isAnswerSkipped"
    [label]="number.skipButtonText || translate('testrunner.freeform.noAnswer')"
  ></p-checkbox>
</form>
